import React, {useState} from 'react'
import OperationType from '../components/OperationType'
import OperationTypeForm from '../components/OperationTypeForm';
import PaymentSuccessPage from '../components/PaymentSuccessPage';
// import AddOperationForm from '../components/OperationType'

function AddOperationPage() {
    const [step, setStep] = useState(1);
    const nextStep = () => {
        setStep(step + 1);
    }
    const prevStep = () => {
        setStep(step - 1);
    }
  return (
    <>
     <div style={{position:'relative'}} className=" mt-16">
       <div className="container-sm mx-auto mt-5 py-2">
        {step == 1 ? (<>
            <h3 className="text-3xl font-bold">New Operation</h3>
            <h6 style={{color:"#848484"}} className="text-sm mt-3">Please select the operation you would like to carry out</h6>
            <OperationType/>
            </>
        ): step == 2 ? (<OperationTypeForm/>) : (<PaymentSuccessPage/>) }
        
        {step !== 3 ? (<>
        <div style={{width:'80%'}} className="container flex justify-end mt-10">
        <button type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Cancel</button>
        <button onClick={prevStep} type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Back</button>
        <button onClick={nextStep} type="button" style={{backgroundColor: '#3E8F0E'}} class="flex items-center focus:outline-none text-white hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
        &nbsp;Next</button>
        </div></>) :''}
       </div>
       </div>
    </>
  )
}

export default AddOperationPage