import React from 'react'

function OperationType() {
  return (
    <>
    <div class="grid grid-cols-2 gap-4">
        <div>
            <label>
                <input type="radio" name="product" class="card-input-element" />
                <div class="panel panel-default card-input p-6 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <h5 className="text-md font-bold">Planting</h5>
                    </div>
                </div>
            </label>
        </div>
        <div>
            <label>
                <input type="radio" name="product" class="card-input-element" />
                <div class="panel panel-default card-input p-6 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <h5 className="text-md font-bold">Crop Monitoring</h5>
                    </div>
                </div>
            </label>

        </div>
        <div>
            <label>
                <input type="radio" name="product" class="card-input-element" />
                <div class="panel panel-default card-input p-6 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <h5 className="text-md font-bold">Fertilizer Application</h5>
                    </div>
                </div>
            </label>
        </div>
        <div>
            <label>
                <input type="radio" name="product" class="card-input-element" />
                <div class="panel panel-default card-input p-6 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <h5 className="text-md font-bold">Pesticide Spraying</h5>
                    </div>
                </div>
            </label>

        </div>
        <div>
            <label>
                <input type="radio" name="product" class="card-input-element" />
                <div class="panel panel-default card-input p-6 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <h5 className="text-md font-bold">Weeding</h5>
                    </div>
                </div>
            </label>
        </div>
        <div>
            <label>
                <input type="radio" name="product" class="card-input-element" />
                <div class="panel panel-default card-input p-6 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <h5 className="text-md font-bold">Harvesting</h5>
                    </div>
                </div>
            </label>

        </div>
    </div>
    </>
  )
}

export default OperationType