import React, {useState} from 'react'
import '@tremor/react/dist/esm/tremor.css';
import { Card, Title, AreaChart, BarChart, Subtitle } from "@tremor/react";
import seeds from "../assets/seeds_planted.svg"
import harvested from "../assets/harvested.svg"
import drone from "../assets/drone.svg"
import money from "../assets/money_analytics.svg"
function AnalyticsPage() {
            const chartdata = [
                {
                date: "Monday",
                Temperature: 40,
                //   "The Pragmatic Engineer": 2338,
                },
                {
                date: "Tuesday",
                Temperature: 30,
                //   "The Pragmatic Engineer": 2103,
                },
                {
                date: "Wednesday",
                Temperature: 20,
                //   "The Pragmatic Engineer": 2194,
                },
                {
                date: "Thursday",
                Temperature: 30,
                //   "The Pragmatic Engineer": 2194,
                },
                {
                date: "Friday",
                Temperature: 25,
                //   "The Pragmatic Engineer": 2194,
                },
                {
                date: "Saturday",
                Temperature: 20,
                //   "The Pragmatic Engineer": 2194,
                },
                {
                date: "Sunday",
                Temperature: 20,
                //   "The Pragmatic Engineer": 2194,
                }
            ];
            const chartdata2 = [
        {
            topic: "Monday",
            "Maize": 890,
            "Groundnut": 338,
            "Soya Bean": 538
        },
        {
            topic: "Tuesday",
            "Maize": 289,
            "Groundnut": 233,
            "Soya Bean": 253
        },
        {
            topic: "Wednesay",
            "Maize": 289,
            "Groundnut": 233,
            "Soya Bean": 253
        },
        {
            topic: "Thursday",
            "Maize": 289,
            "Groundnut": 233,
            "Soya Bean": 253
        },
        {
            topic: "Friday",
            "Maize": 289,
            "Groundnut": 233,
            "Soya Bean": 253
        },
        {
            topic: "Saturday",
            "Maize": 289,
            "Groundnut": 233,
            "Soya Bean": 253
        },
        {
            topic: "Sunday",
            "Maize": 289,
            "Groundnut": 233,
            "Soya Bean": 253
        },
        ];

        const barChartdataFormatter = (number) => {
        return "$ " + Intl.NumberFormat("us").format(number).toString();
        };
            
      const dataFormatter = (number) => {
        return Intl.NumberFormat("us").format(number).toString()+"°C";
      };

      const [displaycard, setDisplayCard] = useState(2);

      const showCard = (id) => {
        console.log(id);
        setDisplayCard(id);
      }

  return (
    <>
     <h3 className="text-3xl font-bold ml-3">Analytics</h3>
     <h6 className="text-lg my-3 font-bold ml-3">Summary</h6>
    <div class="grid grid-cols-2 gap-4">
     <div class="grid grid-cols-2 gap-4">
        <div>
            <label>
                <input type="radio" onClick={()=>showCard(1)} name="product" class="analytics-card-input-element" />
                <div class="panel panel-default card-input p-4 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <div class="grid grid-cols-12 gap-8 flex items-center">
                            <div style={{backgroundColor: "#F5FFE8"}} className="rounded flex items-center justify-center p-3 col-span-3">
                                 <img src={seeds} width="auto" height="25px" />
                            </div>
                            <div className="col-span-9">
                                 <h5 className="text-lg font-bold">350</h5>
                                 <h5 className="text-xs">Seeds Planted</h5>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </label>
        </div>
        <div>
            <label>
                <input type="radio" onClick={()=>showCard(3)} name="product" class="analytics-card-input-element" />
                <div class="panel panel-default card-input p-4 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <div class="grid grid-cols-12 gap-8 flex items-center">
                            <div style={{backgroundColor: "#F5FFE8"}} className="rounded flex items-center justify-center p-3 col-span-3">
                                 <img src={harvested} width="auto" height="25px" />
                            </div>
                            <div className="col-span-9">
                                 <h5 className="text-lg font-bold">6,000kg</h5>
                                 <h5 className="text-xs">Produce Harvested</h5>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </label>
        </div>
        <div>
            <label>
                <input type="radio" name="product" class="analytics-card-input-element" />
                <div class="panel panel-default card-input p-4 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <div class="grid grid-cols-12 gap-8 flex items-center">
                            <div style={{backgroundColor: "#F5FFE8"}} className="rounded flex items-center justify-center p-3 col-span-3">
                                 <img src={drone} width="auto" height="25px" />
                            </div>
                            <div className="col-span-9">
                                 <h5 className="text-lg font-bold">20ha</h5>
                                 <h5 className="text-xs">Total Area Worked</h5>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </label>
        </div>
        <div>
            <label>
                <input type="radio" onClick={()=>showCard(2)} name="product" class="analytics-card-input-element" />
                <div class="panel panel-default card-input p-4 rounded">
                    {/* <div class="panel-heading">Product A</div> */}
                    <div class="panel-body">
                        <div class="grid grid-cols-12 gap-8 flex items-center">
                            <div style={{backgroundColor: "#F5FFE8"}} className="rounded flex items-center justify-center p-3 col-span-3">
                                 <img src={money} width="auto" height="25px" />
                            </div>
                            <div className="col-span-9">
                                 <h5 className="text-lg font-bold">$84.99</h5>
                                 <h5 className="text-xs">Total Amount Spent</h5>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </label>
        </div>
    </div>
    <div>
       { displaycard === 3 ? ( <label>
            <input disabled type="radio" name="product" class="analytics-card-input-element" />
            <div class="panel panel-default card-input p-6 rounded">
                {/* <div class="panel-heading">Product A</div> */}
                <div class="panel-body">
                    <h5 className="text-md font-bold">Produce Harvested</h5>
                    <div className="flex items-center justify-between mt-5">
                        <h6 className="text-xs mr-3">Maize (3,000)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-24">
                            <div className="h-2.5 rounded-full" style={{width: "70%", backgroundColor: "#255906"}}></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-3">
                        <h6 className="text-xs mr-3">Soyabean (1,600)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-24">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "40%", backgroundColor: "#3E8F0E"}}></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-3">
                        <h6 className="text-xs mr-3">Groundnut (1,400)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-24">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "30%", backgroundColor: "#50AB1A"}}></div>
                        </div>
                    </div>
        
                </div>
            </div>
        </label>) : ''}
        { displaycard === 2 ? ( <label>
            <input disabled type="radio" name="product" class="analytics-card-input-element" />
            <div class="panel panel-default card-input p-6 rounded">
                {/* <div class="panel-heading">Product A</div> */}
                <div class="panel-body">
                    <h5 className="text-md font-bold">Total Amount Spent</h5>
                    <div className="flex items-center justify-between mt-5">
                        <h6 className="text-xs mr-3">Planting ($3,000)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-14">
                            <div className="h-2.5 rounded-full" style={{width: "70%", backgroundColor: "#255906"}}></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-3">
                        <h6 className="text-xs mr-3">Spraying Pesticide ($25.99)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-14">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "40%", backgroundColor: "#3E8F0E"}}></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-3">
                        <h6 className="text-xs mr-3">Fertilizer Application ($29.00)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-14">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "30%", backgroundColor: "#50AB1A"}}></div>
                        </div>
                    </div>
        
                </div>
            </div>
        </label>) : ''}
        { displaycard === 1 ? (<label>
            <input disabled type="radio" name="product" class="analytics-card-input-element" />
            <div class="panel panel-default card-input p-6 rounded">
                {/* <div class="panel-heading">Product A</div> */}
                <div class="panel-body">
                    <h5 className="text-md font-bold">Seeds Planted</h5>
                    <div className="flex items-center justify-between mt-5">
                        <h6 className="text-xs mr-3">Maize (1,800)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-24">
                            <div className="h-2.5 rounded-full" style={{width: "70%", backgroundColor: "#255906"}}></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-3">
                        <h6 className="text-xs mr-3">Soyabean (700)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-24">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "40%", backgroundColor: "#3E8F0E"}}></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-3">
                        <h6 className="text-xs mr-3">Groundnut (600)</h6>
                        <div style={{width:"60%"}} className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mr-24">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "30%", backgroundColor: "#50AB1A"}}></div>
                        </div>
                    </div>
        
                </div>
            </div>
              </label> ) : ''
        }
    </div>
    </div>
    <p style={{marginTop: "120px", marginBottom: "120px"}}></p>
    <Card>
    <Title>Weather</Title>
    <AreaChart
      data={chartdata}
      categories={["Temperature"]}
      dataKey="date"
      height="h-72"
      colors={["teal"]}
      valueFormatter={dataFormatter}
      marginTop="mt-4"
    />
  </Card>
  <p style={{marginTop: "100px", marginBottom: "100px"}}></p>
  <Card>
    <Title>Prices This Week</Title>
    <BarChart
      data={chartdata2}
      dataKey="topic"
      categories={[
        "Maize",
        "Groundnut",
        "Soya Bean"
      ]}
      colors={["teal", "green", "teal"]}
      valueFormatter={barChartdataFormatter}
      marginTop="mt-6"
      yAxisWidth="w-12"
    />
  </Card>

    </>
  )
}

export default AnalyticsPage