import React from 'react'
import call from '../assets/call.svg'
import rightArrow from '../assets/contact-arrow.svg'
import chat from '../assets/contact-message.svg'
import email from '../assets/contact-email.svg'

function CustomerCareContact() {
  return (
   <>
    <div style={{border: '1px solid #eee'}} className="grid grid-cols-12 mt-6 round">
        <div className="col-span-6 p-5 flex items-center">
            <img src={call} stye={{height: "60px", width:'auto'}}/>
           <div class=" ml-6">
           <h6 className="text-md font-bold">Call Us</h6>
           <h6 style={{color:"#848484"}} className="text-sm">Contact our call center</h6>
           </div>

        </div>
        <div className="col-span-6 flex items-center justify-end p-5">
        <img src={rightArrow} className="mr-5"/>

        </div>
    </div> 
   <div style={{border: '1px solid #eee'}} className="grid grid-cols-12 mt-6 round">
   <div className="col-span-6 p-5 flex items-center">
            <img src={chat} stye={{height: "60px", width:'auto'}}/>
           <div class=" ml-6">
           <h6 className="text-md font-bold">Chat With Us</h6>
           <h6 style={{color:"#848484"}} className="text-sm">Send us an in-app message</h6>
           </div>

        </div>
        <div className="col-span-6 flex items-center justify-end p-5">
        <img src={rightArrow} className="mr-5"/>

        </div>
    </div> 
    <div style={{border: '1px solid #eee'}} className="grid grid-cols-12 mt-6 round">
        
    <div className="col-span-6 p-5 flex items-center">
            <img src={email} stye={{height: "60px", width:'auto'}}/>
           <div class=" ml-6">
           <h6 className="text-md font-bold">Send An Email</h6>
           <h6 style={{color:"#848484"}} className="text-sm">We'll reply as soon as possible</h6>
           </div>

        </div>
        <div className="col-span-6 flex items-center justify-end p-5">
        <img src={rightArrow} className="mr-5"/>

        </div>
    </div>

   </>
  )
}

export default CustomerCareContact