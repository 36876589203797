import React from 'react'
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

function PaymentSuccessPage() {
    const navigate = useNavigate();
    const { width, height } = useWindowSize()
    setTimeout(()=>{
        navigate('/operations')
    }, 5000)
  return (
    <>
    <h3 className="text-3xl font-bold">Payment Succesful!</h3>
    <h6 style={{color:"#848484"}} className="text-sm mt-3">You will be notified as soon as your operation is active. Manage operations from Dashboard 	&#62; Operations</h6>

    <h6 className="text-center font-bold text-xl mt-24">Redirecting..</h6>

    <Confetti
      width={width/1}
      height={height}
    />
    </>
  )
}

export default PaymentSuccessPage