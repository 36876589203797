import React,{useState} from 'react'
import CustomerCareContact from '../components/CustomerCareContact'
import CustomerCareForm from '../components/CustomerCareForm'

function CustomerCarePage() {
    const [step, setStep] = useState(1);
    const nextStep = () => {
        setStep(step + 1);
    }
    const prevStep = () => {
        setStep(step - 1);
    }
  return (<>
      <h3 className="text-3xl font-bold">We’re Here to Help</h3>
      {step !== 2 ? ( <h6 style={{color:"#848484"}} className="text-sm mt-3">Please keep your communicatin in one message. Please be patient and do not inititate multiple conversations</h6>) : '' }
    {step == 1 ? (<>
      
        <CustomerCareForm/>
        </>
    ):(<CustomerCareContact/>) }
    
    {step !== 2 ? (<>
    <div style={{width:'80%'}} className="container flex justify-end mt-10">
   
    <button onClick={nextStep} type="button" style={{backgroundColor: '#3E8F0E'}} class="flex items-center focus:outline-none text-white hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
    &nbsp;Send</button>
    </div></>) :''}
    </>
  )
}

export default CustomerCarePage