import React from 'react'
import { Link } from 'react-router-dom'
import "../index.css"
import planting from '../assets/planting.png'
import fertilizer from '../assets/fertilizer.png'
import crop from '../assets/crop.png'


function RecentOperationsTable() {
  return (
    <div style={{border: "1px solid #E7E8E7"}} className="p-2 rounded-md">
  <table style={{borderColor:"#E7E8E7"}} className="border-collapse border-b table-auto restbl w-full">
  <thead>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
      <th style={{color:"#7F8380"}} className="py-3 text-start">Operation</th>
      <th style={{color:"#7F8380"}} className="py-3 text-start">Crop</th>
      <th style={{color:"#7F8380"}} className="py-3 text-start">Location</th>
      <th style={{color:"#7F8380"}} className="py-3 text-start">Code</th>
      <th style={{color:"#7F8380"}} className="py-3 text-start">Status</th>
      <th style={{color:"#7F8380", width:"30%"}} className="py-3 text-start">Progress</th>
    </tr>
  </thead>
  <tbody>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
      <td>
        <div className="flex items-center pr-5">
            <div className="pr-3">
            <div style={{background:`url("${planting}")`}} className="op-img"></div>
            </div>
            <div>
            <h3 style={{color:"#353434", cursor: "pointer"}} className="text-md"><Link to="/operations/planting">Planting</Link></h3>
            {/* <h6 style={{color:"#929292"}} className="mt-1 text-xs">View Profile</h6> */}
            </div>
        </div>
      </td>
      <td>
            <h3 style={{color:"#353434"}} className="text-md">Groundnut</h3>
      </td>
      <td>
            <h3 style={{color:"#353434"}} className="text-md">Ayeduase New Site Farm</h3>
      </td>
      <td>
        <h3 style={{color:"#353434"}} className="text-md">#B11024</h3>
      </td>
      <td>
        <div style={{color:"#474747", backgroundColor:"#EAEAEA"}} className="py-2 px-2 rounded-2xl text-center">
            <h3 className="text-xs">Pending</h3>
        </div>  
      </td>
      <td>
        <div className="flex items-center">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className="bg-blue-600 h-2.5 rounded-full" style={{width: "0%"}}></div>
        </div>
        <h6 className="text-xs ml-2">0%</h6>
        </div>
        
      </td>
    </tr>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
      <td>
        <div className="flex items-center pr-5">
            <div className="pr-3">
            <div style={{background:`url("${fertilizer}")`}} className="op-img"></div>
            </div>
            <div>
            <h3 style={{color:"#353434", cursor: "pointer"}} className="text-md"><Link to="/operations/planting">Fertilizer Application</Link></h3>
            {/* <h6 style={{color:"#929292"}} className="mt-1 text-xs">View Profile</h6> */}
            </div>
        </div>
      </td>
      <td>
            <h3 style={{color:"#353434"}} className="text-md">Groundnut</h3>
      </td>
      <td>
            <h3 style={{color:"#353434"}} className="text-md">Ayeduase New Site Farm</h3>
      </td>
      <td>
        <h3 style={{color:"#353434"}} className="text-md">#B11024</h3>
      </td>
      <td>
      <div style={{color:"#474747", backgroundColor:"#E0F8D2"}} className="py-2 px-2 rounded-2xl text-center">
            <h3 style={{color:"#255906"}} className="text-xs">Active</h3>
        </div>   
      </td>
      <td>
        <div className="flex items-center">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className="h-2.5 rounded-full" style={{width: "32%", backgroundColor:"#3E8F0E"}}></div>
        </div>
        <h6 className="text-xs ml-2">32%</h6>
        </div>
        
      </td>
    </tr>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
      <td>
        <div className="flex items-center pr-5">
            <div className="pr-3">
            <div style={{background:`url("${crop}")`}} className="op-img"></div>
            </div>
            <div>
            <h3 style={{color:"#353434", cursor: "pointer"}} className="text-md"><Link to="/operations/planting">Crop Monitoring</Link></h3>
            {/* <h6 style={{color:"#929292"}} className="mt-1 text-xs">View Profile</h6> */}
            </div>
        </div>
      </td>
      <td>
            <h3 style={{color:"#353434"}} className="text-md">Groundnut</h3>
      </td>
      <td>
            <h3 style={{color:"#353434"}} className="text-md">Ayeduase New Site Farm</h3>
      </td>
      <td>
        <h3 style={{color:"#353434"}} className="text-md">#B11024</h3>
      </td>
      <td>
      <div style={{color:"#474747", backgroundColor:"#E0F8D2"}} className="py-2 px-2 rounded-2xl text-center">
            <h3 style={{color:"#255906"}} className="text-xs">Active</h3>
        </div>   
      </td>
      <td>
        <div className="flex items-center">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className="h-2.5 rounded-full" style={{width: "32%", backgroundColor:"#3E8F0E"}}></div>
        </div>
        <h6 className="text-xs ml-2">32%</h6>
        </div>
        
      </td>
    </tr>
   
  </tbody>
</table>
    </div>
  
  )
}

export default RecentOperationsTable