import React from 'react'
import visa from '../assets/visa.png'

function PaymentPage() {
  return (
    <>
     <h3 className="text-3xl font-bold">Payment History</h3>
    <h6 style={{color:"#848484"}} className="text-sm mt-3">Manage billing information and view receipts</h6>
    <div style={{border: "1px solid #E7E8E7"}} className="p-2 rounded-md mt-10">
  <table style={{borderColor:"#E7E8E7"}} className="border-collapse border-b table-auto restbl w-full">
  <thead>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
      <th style={{color:"#7F8380"}} className="py-3 text-start">Date</th>
      <th style={{color:"#7F8380"}} className="py-3 text-start">Operation</th>
      <th style={{color:"#7F8380"}} className="py-3 text-start">Cost</th>
      <th style={{color:"#7F8380"}} className="py-3 text-start">Payment Type</th>
      <th style={{color:"#7F8380", width:"30%"}} className="py-3 text-start">Receipt</th>
    </tr>
  </thead>
  <tbody>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
        <td>
           <span className="font-bold">July 28, 2022</span> 
        </td>  
        <td>
            Planting Groundnut
        </td>
        <td>
            <span className="font-bold">$30</span>
        </td> 
        <td>
            $30 VISA****1234
        </td>
        <td>
        <button type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Download</button> 
       </td>
    </tr>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
        <td>
           <span className="font-bold">July 28, 2022</span> 
        </td>  
        <td>
            Planting Groundnut
        </td>
        <td>
            <span className="font-bold">$30</span>
        </td> 
        <td>
            $30 VISA****1234
        </td>
        <td>
        <button type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Download</button> 
       </td>
    </tr>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
        <td>
           <span className="font-bold">July 28, 2022</span> 
        </td>  
        <td>
            Planting Groundnut
        </td>
        <td>
            <span className="font-bold">$30</span>
        </td> 
        <td>
            $30 VISA****1234
        </td>
        <td>
        <button type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Download</button> 
       </td>
    </tr>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
        <td>
           <span className="font-bold">July 28, 2022</span> 
        </td>  
        <td>
            Planting Groundnut
        </td>
        <td>
            <span className="font-bold">$30</span>
        </td> 
        <td>
            $30 VISA****1234
        </td>
        <td>
        <button type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Download</button> 
       </td>
    </tr>
    <tr style={{borderColor:"#E7E8E7"}} className="border-b">
        <td>
           <span className="font-bold">July 28, 2022</span> 
        </td>  
        <td>
            Planting Groundnut
        </td>
        <td>
            <span className="font-bold">$30</span>
        </td> 
        <td>
            $30 VISA****1234
        </td>
        <td>
        <button type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Download</button> 
       </td>
    </tr>
    <tr>
        <td>
            <span className="mt-5 text-sm" style={{color:'#3E8F0E', textDecoration: "underline"}}>Load more</span>
        </td>
    </tr>
   
  </tbody>
</table>
    </div>
    <h3 className="text-3xl font-bold mt-10">Payment Method</h3>
    <h6 style={{color:"#848484"}} className="text-sm mt-3">Manage how you pay for services on 3Farmate</h6>
    <div style={{border: '1px solid #eee'}} className="grid grid-cols-12 mt-6 round">
        <div className="col-span-6 p-5 flex items-center">
            <img src={visa} stye={{height: "100px", width:'auto'}}/>
            <h6 style={{color:"#848484"}} className="text-sm ml-3">Visa ending in 1234</h6>

        </div>
        <div className="col-span-6 flex items-center justify-end p-5">
        <button type="button" style={{color: "#616161", border: '1px solid #D1D1D1'}} class="flex mr-5 items-center focus:outline-none text-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
        &nbsp;Remove</button>         </div>
    </div>
    </>
  )
}

export default PaymentPage