import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import DeleteAccountModal from '../components/DeleteAccountModal'


function AccountSettingsPage() {
    // const [isOpen, setIsOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const cancelButtonRef = useRef(null);
    const toggleModal = () => {
        setOpen(true);
    }
  return (
    <>
       <h3 className="font-bold text-2xl">Account Settings</h3>
       <h5 className="font-bold text-md mt-6">Profile</h5>

       <div className="grid grid-cols-12">
            <div className="col-span-10 mt-5">
                <h5 className="font-bold text-sm">Name</h5>
                <input style={{border: "1px solid #CBC5C5"}} className="rounded w-full p-3 mt-3" type="text" />
            </div>
            <div className="col-span-10 mt-5">
                <h5 className="font-bold text-sm">Email</h5>
                <input style={{border: "1px solid #CBC5C5"}} className="rounded w-full p-3 mt-3" type="email" />
            </div>
            <div className="col-span-10 mt-5">
                <h5 className="font-bold text-sm">Password</h5>
                <input style={{border: "1px solid #CBC5C5"}} placeholder="Current Password" className="rounded w-full p-3 mt-3" type="password" />
            </div>
            <div className="col-span-10 mt-2">
                <input style={{border: "1px solid #CBC5C5"}} placeholder="New Password" className="rounded w-full p-3 mt-3" type="password" />
            </div>
            <div className="col-span-10 mt-2">
                <input style={{border: "1px solid #CBC5C5"}} placeholder="Retype Password" className="rounded w-full p-3 mt-3" type="password" />
            </div>
        </div>

        <hr className="mt-14"></hr>
        <div className="grid grid-cols-12 mt-6">
            <div className="col-span-6 p-2">
                <h3 style={{color:"#DC0000"}} className="font-bold text-2xl">Delete Account</h3>
                <h5 className="text-sm mt-2">All your data will be deleted and your account will no longer be available</h5>
            </div>
            <div className="col-span-6 flex items-center justify-end p-2">
                <button onClick={toggleModal} style={{backgroundColor:"#DC0000", color:"white"}} className="p-3 px-6 rounded text-sm">Delete</button>
                <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Deactivate account
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All of your data will be permanently
                          removed. This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
            </div>
        </div>
        <div className="grid grid-cols-12 mt-5">
            <div className="col-span-12 mt-3">
                {/* <h5 className="font-bold text-sm">Name</h5> */}
                <div style={{border: "1px solid #CBC5C5"}}
                    className="rounded w-full p-3 mt-3 flex items-center justify-start">
                    <div class="flex items-start items-center space-x-3 py-6">
                        <input type="checkbox" style={{accentColor:"#3E8F0E"}} checked class="border-gray-300 rounded h-5 w-5 ml-2" />

                        <div class="flex flex-col">
                            <h1 class="text-gray-700 font-bold font-medium leading-none ml-3">Promotional Videos</h1>
                            {/* <p class="text-xs text-gray-500 mt-2 leading-4">A plugin that provides a basic reset for
                                form styles that makes form elements easy to override with utilities.</p> */}
                        </div>
                    </div>
                </div>
                <div style={{border: "1px solid #CBC5C5"}}
                    className="rounded w-full p-3 mt-3 flex items-center justify-start">
                    <div class="flex items-start items-center space-x-3 py-6">
                        <input type="checkbox" style={{accentColor:"#3E8F0E"}} checked  class="border-gray-300 rounded h-5 w-5 ml-2" />

                        <div class="flex flex-col">
                            <h1 class="text-gray-700 font-bold font-medium leading-none ml-3">Product Updates</h1>
                            {/* <p class="text-xs text-gray-500 mt-2 leading-4">A plugin that provides a basic reset for
                                form styles that makes form elements easy to override with utilities.</p> */}
                        </div>
                    </div>
                </div>
                <div style={{border: "1px solid #CBC5C5"}}
                    className="rounded w-full p-3 mt-3 flex items-center justify-start">
                    <div class="flex items-start items-center space-x-3 py-6">
                        <input type="checkbox" style={{accentColor:"#3E8F0E"}} checked  class="border-gray-300 rounded h-5 w-5 ml-2" />

                        <div class="flex flex-col">
                            <h1 class="text-gray-700 font-bold font-medium leading-none ml-3">Newsletter</h1>
                            {/* <p class="text-xs text-gray-500 mt-2 leading-4">A plugin that provides a basic reset for
                                form styles that makes form elements easy to override with utilities.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="flex justify-end items-center mt-5">
            <button style={{backgroundColor:"white", color:"#616161", border:"1px solid #D1D1D1"}} className="p-3 px-6 rounded text-sm mr-4">Cancel</button>
            <button style={{backgroundColor:"#3E8F0E", color:"white"}} className="p-3 px-6 rounded text-sm">Save Changes</button>
        </div>
    </>
  )
}

export default AccountSettingsPage