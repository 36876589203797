import React from 'react'

function OperationTypeForm() {
  return (
    <>
    <h3 className="text-3xl font-bold">Planting</h3>
    <h6 style={{color:"#848484"}} className="text-sm mt-3">Please fill out the following information correctly</h6>
    <div className="col-span-8 mt-5">
        <h5 className="font-bold text-sm">What crop are you planting?</h5>
        <input placeholder='Maize' style={{border: "1px solid #CBC5C5", width:"80%"}} className="rounded p-3 mt-3" type="email" />
    </div>
    <div className="col-span-8 mt-5">
        <h5 className="font-bold text-sm">When should planting start?</h5>
        <input placeholder='July 29, 2022' style={{border: "1px solid #CBC5C5", width:"80%"}} className="rounded p-3 mt-3" type="email" />
    </div>
    <div className="col-span-8 mt-5">
        <h5 className="font-bold text-sm">Seed Rate</h5>
        <input placeholder='0 seeds per stand' style={{border: "1px solid #CBC5C5", width:"80%"}} className="rounded p-3 mt-3" type="email" />
    </div>
    <h5 className="font-bold text-sm mt-5">Area of the Farm</h5>
    <div style={{width:'80%'}} className="grid grid-cols-12">
    <div className="col-span-6 mt-5 pr-2">
        <h5 style={{color: '#999999'}} className="font-bold text-xs">Length</h5>
        <input placeholder='0m' style={{border: "1px solid #CBC5C5", width:"100%"}} className="rounded p-3 mt-3" type="email" />
    </div>
    <div className="col-span-6 mt-5 pl-2">
        <h5 style={{color: '#999999'}} className="font-bold text-xs">Width</h5>
        <input placeholder='0m' style={{border: "1px solid #CBC5C5", width:"100%"}} className="rounded p-3 mt-3" type="email" />
    </div>
    </div>
    <h5 className="font-bold text-sm mt-5">Planting Spacing</h5>
    <div style={{width:'80%'}} className="grid grid-cols-12">
    <div className="col-span-6 mt-5 pr-2">
        <h5 style={{color: '#999999'}} className="font-bold text-xs">Distance between rows</h5>
        <input placeholder='0m' style={{border: "1px solid #CBC5C5", width:"100%"}} className="rounded p-3 mt-3" type="email" />
    </div>
    <div className="col-span-6 mt-5 pl-2">
        <h5 style={{color: '#999999'}} className="font-bold text-xs">Distance between plants in a row (in centimeters)</h5>
        <input placeholder='0cm' style={{border: "1px solid #CBC5C5", width:"100%"}} className="rounded p-3 mt-3" type="email" />
    </div>
    </div>
    
    </>
  )
}

export default OperationTypeForm