import React,{useState} from 'react'
import { Link} from 'react-router-dom';
import notif from '../assets/notif.png'
import userImg from '../assets/user.png'

function NavbarContent() {
    const [shownotif,Setshownotif] = useState(0);
    const [notifStyles, SetnotifStyles] = useState({display:'none'});

    // var notifStyles = {display:'none'};
    const toggleNotifModal = () => {
        // alert("hey");
        if(shownotif == 1){
            Setshownotif(0);
            SetnotifStyles({display:'none'});
            
        }
        else{
            Setshownotif(1);
            SetnotifStyles({left:'-100px'}); 
           
         } 
    //    Setshownotif({left:"-100px"});
    }
  return (

    <div style={{justifyContent:"end"}} className="flex items-end mb-9">
            <div style={{justifyContent:"end"}} className="flex items-center">
          
                <div className="px-8 relative">
                    <button onClick={toggleNotifModal} style={{backgroundColor: "#EEF6ED"}} className="p-2 rounded-md relative"><img
                            style={{height:"25px", width:"auto"}} src={notif} /> 
                            <span style={{height:"10px", width:"10px", backgroundColor:"#DC0000", borderRadius:"100px", position:"absolute", top:"0px", right:"0px"}}></span>
                            </button>
                             {/** notification popup */}
                              <div style={notifStyles} className={"shadow-md bg-white absolute w-80 mt-3 rounded p-3"}>
                                {/* <div className="flex"> */}
                                <div className="grid grid-cols-12">
                                    <div className="col-span-6 flex items-center p-2">
                                        <h5 className="text-sm font-bold">Notifications</h5>
                                    </div>
                                    <div className="col-span-6 flex items-center justify-end p-2">
                                        <h6 style={{color:"rgb(62, 143, 14)"}} className="text-xs font-bold">Settings</h6>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12 my-4">
                                    <div className="col-span-2 flex items-start justify-center p-2">
                                        {/* <h5 className="text-sm"> */}
                                            <span style={{height:"10px", width:"10px", backgroundColor:"#3E8F0E", borderRadius:"100px"}}></span>
                                        {/* </h5> */}
                                    </div>
                                    <div className="col-span-10 flex items-center p-2">
                                        <h6 className="text-xs">
                                        An operation you created recently: ‘Fertilizer Application’ at Ayeduase New Site Farm, is now active.
                                        </h6>
                                    </div>
                                    <div className="col-span-2 flex items-start justify-center p-2">
                                        {/* <h5 className="text-sm"> */}
                                            <span style={{height:"10px", width:"10px", backgroundColor:"#3E8F0E", borderRadius:"100px"}}></span>
                                        {/* </h5> */}
                                    </div>
                                    <div className="col-span-10 flex items-center p-2">
                                        <h6 className="text-xs">
                                        An operation you created recently: ‘Fertilizer Application’ at Ayeduase New Site Farm, is now active.
                                        </h6>
                                    </div>
                                </div>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-6 flex items-center p-2">
                                        <h5 className="text-sm font-bold">Mark all as read</h5>
                                    </div>
                                    <div className="col-span-6 flex items-center justify-end p-2">
                                        <h6 className="text-sm font-bold">
                                            <Link to="/notifications">
                                                View All
                                                </Link>
                                    </h6>
                                    </div>
                                </div>
                                {/* </div> */}
                              </div>
                              {/** end of notification popup */}
                </div>
                <div className="flex items-center pr-5">
                    <div class="pr-3">
                        <div style={{background:`url("${userImg}")`}} className="user-img"></div>
                    </div>
                    <div>
                        <h3 className="text-md font-bold">John Joseph</h3>
                        <Link to="/account-settings" style={{color:"#929292"}} className="mt-1 text-xs font-bold">View Profile</Link>
                    </div>
                </div>
              
            </div>
        </div>
  )
}

export default NavbarContent