import React from 'react'
import RecentOperationsTable from '../components/RecentOperationsTable'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OperationsPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (<>
     <div style={{position:'relative'}} className=" mt-16">
       <h3 className="text-xl font-bold">Operations</h3>
       <h6 style={{color:"#848484"}} className="text-sm mt-3">Track the status of operations on your farm</h6>
      <Link style={{zIndex: '5'}} to="/add-new-operation">
      <button type="button" style={{backgroundColor: '#3E8F0E', position: 'absolute', right: '0px', top: '90px',zIndex: '5'}} class="flex items-center focus:outline-none text-white hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>&nbsp;Add New</button>
      </Link>

     </div>
     <div className="container-sm mx-auto mt-5 py-2">
     <Box sx={{ width: '100%', paddingLeft: '0px' }}>
      <Box sx={{}}>
        <Tabs 
        textColor="success"
        TabIndicatorProps={{
        style: {
          backgroundColor: "#255906",
          color: "#255906"
        }
      }}
        value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Active" {...a11yProps(1)} />
          <Tab label="Completed" {...a11yProps(2)} />
          <Tab label="Pending" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <RecentOperationsTable />      
      </TabPanel>
      <TabPanel value={value} index={1}>
      <RecentOperationsTable />      </TabPanel>
      <TabPanel value={value} index={2}>
      <RecentOperationsTable />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <RecentOperationsTable />
      </TabPanel>
    </Box>
       
     </div>
     </>
  )
}

export default OperationsPage