import React from 'react'
import {Link} from 'react-router-dom'

function NotificationsPage() {
  return (
    <>
    <h3 className="font-bold text-2xl">Notifications</h3>
        <div className="grid grid-cols-12 my-4">
            
            <div className="col-span-12 flex items-center justify-start p-2">
                {/* <h5 className="text-sm"> */}
                    <span style={{height:"10px", width:"10px", backgroundColor:"#3E8F0E", borderRadius:"100px"}}></span>
                {/* </h5> */}
           
                <h6 className="text-md ml-3">
                An operation you created recently: ‘Fertilizer Application’ at Ayeduase New Site Farm, is now active.
                </h6>
            </div>
            <div className="col-span-12 flex items-center justify-start p-2">
                {/* <h5 className="text-sm"> */}
                    <span style={{height:"10px", width:"10px", backgroundColor:"#3E8F0E", borderRadius:"100px"}}></span>
                {/* </h5> */}
           
                <h6 className="text-md ml-3">
                An operation you created recently: ‘Fertilizer Application’ at Ayeduase New Site Farm, is now active.
                </h6>
            </div>
        </div>
        <div className="grid grid-cols-12">
            <div className="col-span-6 flex items-center p-2">
                <h5 style={{textDecoration:"underline", color: "#3E8F0E"}} className="text-sm font-bold">Mark all as read</h5>
            </div>
          
     </div>
    </>
  )
}

export default NotificationsPage