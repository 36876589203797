import React from 'react'
import hotSun from '../assets/hot_sun.png'
import wind from '../assets/wind.png'
import humidity from '../assets/humidity.png'
import heavyRain from '../assets/heavy_rain.png'
import cloudy from '../assets/cloudy.png'

function WeatherCard() {
  return (
    <div class="grid grid-cols-12">
    <div style={{border: "1px solid #E7E8E7"}} className="p-6 rounded-l-md col-span-4">
      <h3 class="text-xl font-bold">Today</h3>
      <h6 style={{color:"#848484"}} class="mt-1 text-sm">Gurugu,Tamale</h6>
      <div className="flex items-center mt-3">
        <h3 class="text-md font-bold my-0">35°C/Sunny</h3>
        <img class="ml-3" src={hotSun} />
      </div>
      <div className="flex items-center mt-4">
      <img src={wind} />
      <h6 style={{color:"#848484"}} class="text-xs px-2">Wind&nbsp;&nbsp;|&nbsp;&nbsp;22km/h</h6>
      <img class="pl-2" src={humidity} />
      <h6 style={{color:"#848484"}} class="text-xs px-2">Humidity&nbsp;&nbsp;|&nbsp;&nbsp;19%</h6>
      </div>
    </div>
    <div style={{border: "1px solid #E7E8E7"}} className="px-2 py-6 rounded-r-md col-span-8">
      <div className="container">
        <h6 style={{color:"#848484"}} class="mt-1 ml-12 text-sm">Week Forecast</h6>
        
            <div className="grid grid-cols-12 mt-4">
                <div className="col-span-2 grid grid-cols-1">
                    <img class="justify-self-center" src={hotSun} />
                    <h6 style={{color:"#848484"}} class="mt-4 text-sm justify-self-center">Wed</h6>
                    <h3 class="text-md font-bold justify-self-center">19°</h3>
                </div>
                <div className="col-span-2 grid grid-cols-1">
                    <img class="justify-self-center" src={heavyRain} />
                    <h6 style={{color:"#848484"}} class="mt-4 text-sm justify-self-center">Thur</h6>
                    <h3 class="text-md font-bold justify-self-center">19°</h3>
                </div>
                <div className="col-span-2 grid grid-cols-1">
                    <img class="justify-self-center" src={hotSun} />
                    <h6 style={{color:"#848484"}} class="mt-4 text-sm justify-self-center">Fri</h6>
                    <h3 class="text-md font-bold justify-self-center">19°</h3>
                </div>
                <div className="col-span-2 grid grid-cols-1">
                    <img class="justify-self-center" src={cloudy} />
                    <h6 style={{color:"#848484"}} class="mt-4 text-sm justify-self-center">Sat</h6>
                    <h3 class="text-md font-bold justify-self-center">19°</h3>
                </div>
                <div className="col-span-2 grid grid-cols-1">
                    <img class="justify-self-center" src={cloudy} />
                    <h6 style={{color:"#848484"}} class="mt-4 text-sm justify-self-center">Sun</h6>
                    <h3 class="text-md font-bold justify-self-center">19°</h3>
                </div>
                <div className="col-span-2 grid grid-cols-1">
                    <img class="justify-self-center" src={hotSun} />
                    <h6 style={{color:"#848484"}} class="mt-4 text-sm justify-self-center">Mon</h6>
                    <h3 class="text-md font-bold justify-self-center">19°</h3>
                </div>
               
            </div>
       
      </div>
    </div>
  </div>
  )
}

export default WeatherCard