import React from 'react'

function CustomerCareForm() {
  return (
    <div className="col-span-10 mt-5">
    <h5 className="font-bold text-sm">Your Message</h5>
    <textarea style={{border: "1px solid #CBC5C5", width: "80%", height: "300px"}} className="rounded p-3 mt-3"></textarea>
</div>
  )
}

export default CustomerCareForm