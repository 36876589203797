import React,{useState} from 'react'
import '../index.css'
import logo from '../assets/logo.png'
import NavbarContent from './NavbarContent'
import  {Link} from 'react-router-dom';

function Sidebar(props) {
 const [page, setPage] = useState(1);

  return (
    
    <div className="flex">
    <div className="flex flex-col h-screen p-5 bg-white shadow w-64">
        <div className="space-y-3">
            <div className="flex items-center">
                <img src={logo} style={{height:"60px", width:"auto"}}/>
            </div>
            <div className="flex mt-5 items-center">
                <h4 style={{color:"#3E8F0E"}} className="ml-2 font-bold">Dashboard</h4>
            </div>
            <div className="flex-1">
                <ul className="pt-2 pb-4 space-y-1 text-sm">
                    <li className={page === 1 ? "rounded-sm highlight" : "rounded-sm"}>
                        <Link
                            to="/"
                            onClick={()=>setPage(1)}
                            className="flex items-center p-2 space-x-3 rounded-md"
                        >
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.15722 19.7714V16.7047C8.1572 15.9246 8.79312 15.2908 9.58101 15.2856H12.4671C13.2587 15.2856 13.9005 15.9209 13.9005 16.7047V19.7809C13.9003 20.4432 14.4343 20.9845 15.103 21H17.0271C18.9451 21 20.5 19.4607 20.5 17.5618V8.83784C20.4898 8.09083 20.1355 7.38935 19.538 6.93303L12.9577 1.6853C11.8049 0.771566 10.1662 0.771566 9.01342 1.6853L2.46203 6.94256C1.86226 7.39702 1.50739 8.09967 1.5 8.84736V17.5618C1.5 19.4607 3.05488 21 4.97291 21H6.89696C7.58235 21 8.13797 20.4499 8.13797 19.7714" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                            <span>Overview</span>
                        </Link>
                    </li>
                    <li className={page === 2 ? "rounded-sm mt-12 highlight" : "rounded-sm mt-12"}>
                        <Link
                            to="/operations"
                            onClick={()=>setPage(2)}
                            className="flex items-center p-2 space-x-3 rounded-md"
                        >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.73901V6H8C4.829 6 2.151 8.108 1.29 11H0V15H1.29C2.151 17.892 4.829 20 8 20C9.308 20 10.692 20 12 20C15.171 20 17.849 17.892 18.71 15H20V11H18.71C17.849 8.108 15.171 6 12 6H11V3.745C11.603 3.399 12.009 2.749 12.009 2.005C12.009 0.898005 11.111 0 10.005 0C8.898 0 8 0.898005 8 2.005C8 2.745 8.402 3.39201 9 3.73901ZM3 13C3 13.552 3 13 3 13C3 10.239 5.239 8 8 8C9.308 8 10.692 8 12 8C14.761 8 17 10.239 17 13C17 13.004 17 13.009 17 13.013C17 15.761 14.761 18 12 18C10.692 18 9.308 18 8 18C5.239 18 3 15.761 3 13ZM12 12V14H14V12H12ZM6 12V14H8V12H6Z" fill="#6F6F6F"/>
                        </svg>

                            <span>Operations</span>
                        </Link>
                    </li>
                    <li className={page === 3 ? "rounded-sm mt-12 highlight" : "rounded-sm mt-12"}>
                        <Link
                            to="/analytics"
                            onClick={()=>setPage(3)}
                            className="flex items-center p-2 space-x-3 rounded-md"
                        >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.37109 10.2021V17.0621" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M12.0381 6.91895V17.0619" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M16.6289 13.8271V17.0621" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
                                stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                            <span>Analytics</span>
                        </Link>
                    </li>
                    <li className={page === 4 ? "rounded-sm mt-12 highlight" : "rounded-sm mt-12"}>
                        <Link
                            to="/payments"
                            onClick={()=>setPage(4)}
                            className="flex items-center p-2 space-x-3 rounded-md"
                        >
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 4C20 1.791 18.209 0 16 0H4C1.791 0 0 1.791 0 4V12C0 14.209 1.791 16 4 16H16C18.209 16 20 14.209 20 12V4ZM18 6V12C18 13.105 17.105 14 16 14H4C2.895 14 2 13.105 2 12V6H18ZM12 12H16V10H12V12ZM18 4H2C2 2.895 2.895 2 4 2H16C17.105 2 18 2.895 18 4Z" fill="#6F6F6F"/>
                        </svg>

                            <span>Payments & Billings</span>
                        </Link>
                    </li>
                    <li className={page === 5 ? "rounded-sm mt-12 highlight" : "rounded-sm mt-12"}>
                        <Link
                            to="/customer-care"
                            onClick={()=>setPage(5)}
                            className="flex items-center p-2 space-x-3 rounded-md"
                        >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H14.59L18.29 19.71C18.3834 19.8027 18.4943 19.876 18.6161 19.9258C18.7379 19.9755 18.8684 20.0008 19 20C19.1312 20.0034 19.2613 19.976 19.38 19.92C19.5626 19.845 19.7189 19.7176 19.8293 19.5539C19.9396 19.3901 19.999 19.1974 20 19V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 16.59L15.71 14.29C15.6166 14.1973 15.5057 14.124 15.3839 14.0742C15.2621 14.0245 15.1316 13.9992 15 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V16.59Z" fill="#6F6F6F"/>
                        </svg>
                            <span>Customer Care</span>
                        </Link>
                    </li>
                    {/* <li className="rounded-sm">
                        <a
                            href="#"
                            className="flex items-center p-2 space-x-3 rounded-md"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                />
                            </svg>
                            <span>Logout</span>
                        </a>
                    </li> */}
                </ul>
            </div>
        </div>
    </div>
    
    <div style={{height:"100vh", overflowY:"scroll"}} className="container p-5 px-10">
        <NavbarContent/>
      {props.children}
    </div>
</div>

  )
}

export default Sidebar