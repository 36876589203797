import React from 'react'
// import Sidebar from '../components/Sidebar'
// import { BrowserRouter as Router } from 'react-router-dom';

import "../index.css";
import WeatherCard from '../components/WeatherCard';
import RecentOperationsTable from '../components/RecentOperationsTable';
import BlogCard from '../components/BlogCard';

function Homepage() {
  return (
    // <Router>
    <>
      {/* Greeting and WeatherCard */}
      <h3 class="text-2xl font-bold">Overview</h3>
      <h6 style={{color:"#848484"}} class="mt-1">Welcome back, John!</h6>
      <div className="container-sm mx-auto mt-5 py-2">
        <WeatherCard/>
      </div>

      {/** Recent Operations  */}
      <div className="flex justify-between items-center mt-16">
        <h3 class="text-xl font-bold">Recent Operations</h3>
        <button style={{backgroundColor:"#50AB1A"}} class="rounded-md py-3 flex items-center text-sm text-white px-3">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          &nbsp;&nbsp;Add New
      </button>
      </div>
     
      {/* <h6 style={{color:"#848484"}} class="mt-1">Welcome back, John!</h6> */}
      <div className="container-sm mx-auto mt-5 py-2">
        <RecentOperationsTable/>
      </div>

      {/** Lastest From Blog  */}
      <div className="flex justify-between items-center mt-16">
        <h3 class="text-xl font-bold">Lastest From Blog</h3>
        
      </div>
     
      {/* <h6 style={{color:"#848484"}} class="mt-1">Welcome back, John!</h6> */}
      <div className="container-sm mx-auto mt-5 py-2">
        <div className="grid grid-cols-12">
          <div className="col-span-3 p-2">
            <BlogCard/>
          </div>
          <div className="col-span-3 p-2">
            <BlogCard/>
          </div>
          <div className="col-span-3 p-2">
            <BlogCard/>
          </div>
          <div className="col-span-3 p-2">
            <BlogCard/>
          </div>
        </div>
      
      </div>
    </>
  //  </Router>
  )
}

export default Homepage