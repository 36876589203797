import React from 'react'
import { Link } from 'react-router-dom';
import map from '../assets/map.png';

function OperationOverviewPage() {
  return (
    <>
    <div className=" mt-16">
       <h3 className="text-3xl font-bold">Planting Maize</h3>
       <h6 style={{color:"#848484"}} className="text-sm mt-3">Ayeduase New Site Farm - #811024</h6>
     </div>
     <div className="container-sm mx-auto mt-5 py-2">
        <div style={{background:`url(${map})`, height: '300px'}} className="container"></div>
        <h6 className="text-md ml-2 mt-5 font-bold">32% Completed</h6>
        <div className="flex items-center mt-3">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className="h-2.5 rounded-full" style={{width: "32%", backgroundColor:"#3E8F0E"}}></div>
        </div>
        </div>

        <h6 className="mt-10 font-bold">Details</h6>        
        <ol className="relative border-l border-gray-200 dark:border-gray-700 mt-3">                  
            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Planting Start Date:</h3>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">July 28, 2022</time>
                {/* <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p> */}
            </li>
            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">End Date:</h3>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">August 9, 2022</time>
                {/* <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p> */}
            </li>
            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Total Land Area:</h3>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">15, 000 Sq meters</time>
                {/* <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p> */}
            </li>
            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Inter row spacing:</h3>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">40cm x 40cm</time>
                {/* <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p> */}
            </li>
            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Intra row spacing:</h3>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">15cm x 15cm</time>
                {/* <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p> */}
            </li>
            <li className="mb-10 ml-4">
                <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Seeds rate:</h3>
                <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">4 seeds per stand</time>
                {/* <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p> */}
            </li>
        </ol>
        
        <Link to="/operations">
            <p style={{color: '#3E8F0E'}} className='text-sm mt-5 flex items-center'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
            </svg>
            &nbsp; Go Back</p>
        </Link>
     </div>
    </>
  )
}

export default OperationOverviewPage