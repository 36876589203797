import {BrowserRouter as Router, Routes as Switch, Route} from "react-router-dom";
import Homepage from "./pages/Homepage";
import OtherPage from "./pages/OtherPage";
import NotificationsPage from "./pages/NotificationsPage";
import OperationsPage from "./pages/OperationsPage";
import AccountSettingsPage from "./pages/AccountSettingsPage";
import Sidebar from "./components/Sidebar";
import OperationOverviewPage from "./pages/OperationOverviewPage";
import AddOperationPage from "./pages/AddOperationPage";
import PaymentPage from "./pages/PaymentPage";
import CustomerCarePage from "./pages/CustomerCarePage";
import AnalyticsPage from "./pages/AnalyticsPage";
function App() {
  return (
   <Router>
    <Switch>
      <Route path="/" element={<Sidebar children={<Homepage/>}></Sidebar>}/>
      <Route path="/page" element={<Sidebar children={<OtherPage/>}></Sidebar>}/>
      <Route exact path="/notifications" element={ <Sidebar children={<NotificationsPage/>}></Sidebar>}/>
      <Route exact path="/operations" element={ <Sidebar children={<OperationsPage/>}></Sidebar>}/>
      <Route exact path="/operations/:id" element={ <Sidebar children={<OperationOverviewPage/>}></Sidebar>}/>
      <Route exact path="/add-new-operation" element={ <Sidebar children={<AddOperationPage/>}></Sidebar>}/>
      <Route exact path="/account-settings" element={ <Sidebar children={<AccountSettingsPage/>}></Sidebar>}/>
      <Route exact path="/payments" element={ <Sidebar children={<PaymentPage/>}></Sidebar>}/>
      <Route exact path="/customer-care" element={ <Sidebar children={<CustomerCarePage/>}></Sidebar>}/>
      <Route exact path="/analytics" element={ <Sidebar children={<AnalyticsPage/>}></Sidebar>}/>
    </Switch>
   </Router>
  );
}

export default App;
