import React from 'react'
import farmer from '../assets/farmer.png';

function BlogCard() {
  return (
    <>
     <div style={{background:`url("${farmer}")`}} className="news-img"></div>
     <h3 style={{color:"#353434"}} className="text-md mt-3 font-bold">How We are Creating a  New Narrative For  Smallholder Farmers in Africa</h3>
     <p style={{color:"#616161"}} className="text-sm">
     Lorem ipsum dolor sit amet conse ctetur adipiscing elit porttitor, cur sus ligula class donec mi inceptos
     </p>
    </>
  )
}

export default BlogCard